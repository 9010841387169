import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true,
      guestPermission: true
    }
  },
  {
    path: '/olivos',
    name: 'olivos',
    component: () => import('@/views/OlivosView.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true,
      guestPermission: true
    }
  },
  {
    path: '/olivos/:id/:plot_id?',
    name: 'olivos-files',
    component: () => import('@/views/OlivosFilesView.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true,
      guestPermission: true
    }
  },
  {
    path: '/olivos-files/:id',
    name: 'olivo-files-show',
    component: () => import('@/views/OlivoFilesShow.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true,
      guestPermission: true
    }
  },
  {
    path: '/plots',
    name: 'plots',
    component: () => import('@/views/PlotsView.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true,
      guestPermission: true
    }
  },
  {
    path: '/plots/:plot_id',
    name: 'plots-show',
    component: () => import('@/views/PlotView.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true,
      guestPermission: true
    }
  },
  {
    path: '/olivos-pending',
    name: 'olivos-pending',
    component: () => import('@/views/FilesView.vue'),
    meta: {
      layout: 'default',
      requiresAuth: true,
      guestPermission: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'auth',
      requiresAuth: false,
      guestPermission: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  const isGuest = localStorage.getItem('guest');

  window.scrollTo(0, 0);
  if (to.meta.requiresAuth) {
    if (!token) {
      if (to.meta.guestPermission && isGuest)
        return next();
      return next('/login');
    }
    return next();

  } else {
    if (token || isGuest) {
      return next('/');
    }
  }

  return next();
});

export default router
