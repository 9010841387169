import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';

import '@/registerServiceWorker'
import '@/sass/main.scss';
import '@/filters/filters.js';

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
Vue.use(PulseLoader)

import VueOffline from 'vue-offline'
Vue.use(VueOffline)

import Notifications from 'vue-notification'
Vue.use(Notifications)

import * as VueGoogleMaps from 'vue2-google-maps'
 
console.log(process.env.VUE_APP_KEY_GOOGLE);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAs1lwkx5trAVqYU9Ad2N2UdgJyTnYRI18',
    libraries: 'places'
  },
})

axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status === 401) {
    store.dispatch('auth/logout');
    router.push('/login');
    this.$notify({
      title: "Ups!",
      text:  "Sesión caducada",
      type:  "warn"
    });
  }
  
  return Promise.reject(error)
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
