const API_URL = process.env.VUE_APP_ENDPOINT_API;
// const USER = JSON.parse(localStorage.getItem('token'));
import axios from 'axios';

class PlotService {

    getPlots () {
        return new Promise((resolve, reject) => {
            axios({
                url: API_URL + 'pwa/plots',
                method: 'GET',
            }).then(resp => {
                resolve(resp.data)
            })
            .catch(err => {
                reject(err.response === undefined ? err : err.response.data.message)
            })
        })
    }

    getPlot (id) {
        return new Promise((resolve, reject) => {
            axios({
                url: API_URL + 'pwa/plots/' + id,
                method: 'GET',
            }).then(resp => {
                resolve(resp.data)
            })
            .catch(err => {
                reject(err.response === undefined ? err : err.response.data.message)
            })
        })
    }
}

export default new PlotService ()