const API_URL = process.env.VUE_APP_ENDPOINT_API;
const USER = JSON.parse(localStorage.getItem('token'));
import axios from 'axios';

class authService {

    login (data) {
        return new Promise((resolve, reject) => {
            axios({
                url: API_URL + 'tokens',
                method: 'POST',
                data
            }).then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err.response.data.message)
            })
        })
    }

    user () {
        return new Promise((resolve, reject) => {
            axios({
                url: API_URL + 'tokens/user',
                method: 'POST',
                headers: {"Authorization" : `Bearer ${USER.id}`}
            }).then(resp => {
                resolve(resp)
            })
            .catch(err => {
                reject(err)
            })
        })
    }
}

export default new authService ()