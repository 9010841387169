export default {
    namespaced: true,
    state: () => ({
        trees: [],
        current_tree: [],
    }),
    mutations: {
        setCurrentTree (state, value) {
            state.current_tree = value;
        },
        setTrees (state, value) {
            state.trees = value;
        },
        toggleLoadingTree (state, key) {
            let objIndex = state.trees.findIndex((element => element.key == key));
            if (state.trees[objIndex])
                state.trees[objIndex].loading = !state.trees[objIndex].loading;
        },
        removeTree (state, key) {
            state.trees = state.trees.filter((element => element.key != key));
        }
    },
    getters: {
        trees (state) {
            return state.trees;
        },
        current_tree (state) {
            return state.current_tree;
        },
    },
    actions: {
        removeTree ({commit}, key) {
            commit("removeTree", key);
        },
        setTrees ({commit}, trees) {
            commit("setTrees", trees);
        },
        toggleLoadingTree ({commit}, key) {
            commit("toggleLoadingTree", key);
        },
        setCurrentTree ({commit}, tree) {
            commit("setCurrentTree", tree);
        }
    },
};