<template>
  <div id="app"
    :class="{'max-h-screen overflow-hidden' : isLoading}"
  >
    <LoadingApp v-show="isLoading"/>
    <vue-extend-layouts v-if="!isLoading"/>
  </div>
</template>

<script>
  import VueExtendLayouts from 'vue-extend-layout'
  import LoadingApp from '@/components/LoadingApp'
  import { mapActions, mapGetters  } from 'vuex'

  export default {
    name: 'App',
    components: { VueExtendLayouts, LoadingApp },
    data() {
      return {
        isLoading: true
      }
    },
    computed: {
      ...mapGetters({
        'isAuthenticated': 'auth/isAuthenticated',
        'user': 'auth/user'
      })
    },
    methods: {
      ...mapActions({
        'initUser': 'auth/getUser',
        'initPlots': 'plots/getPlots'
      })
    },
    mounted() {
      if (this.isOnline) {
        Promise.all([
          this.initUser()
        ]).then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
      }
    }
  }
</script>