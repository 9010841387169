import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import auth from "./modules/auth";
import plots from "./modules/plots";
import trees from "./modules/trees";
import breadcrumbs from "./modules/breadcrumbs";

export default new Vuex.Store({
  modules: {
    auth,
    breadcrumbs,
    plots,
    trees
  }
});