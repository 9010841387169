import AuthService from '@/services/auth.service.js'

export default {
    namespaced: true,
    state: () => ({
        authenticated: false,
        token: null,
        user: {},
        guest: localStorage.getItem('guest')
    }),
    mutations: {
        setAuthenticated(state, value) {
            state.authenticated = value;
        },
        removeToken (state) {
            state.user  = {};
            state.token = null;
            state.authenticated = false;
            localStorage.removeItem('token');
            localStorage.removeItem('guest');
        },
        setToken (state, value) {
            state.token = value;
        },
        setUser(state, value) {
            state.user = value;
        },
        setGuest(state, value) {
            state.guest = value;
        },
    },
    getters: {
        isAuthenticated (state) {
            return state.authenticated;
        },
        getToken (state) {
            return state.token;
        },
        user (state) {
            return state.user;
        },
        isGuest(state) {
            return state.guest;
        },
    },
    actions: {
        login ({commit}, data) {
            return new Promise((resolve, reject) => {
                AuthService.login(data)
                .then(resp => {
                    commit("setToken", resp.data);
                    commit("setUser", {
                        email: resp.data.email,
                        name: resp.data.name,
                        lastname: resp.data.lastname
                    });
                    commit("setAuthenticated", true);
                    localStorage.setItem('token', JSON.stringify(resp.data));
                    resolve(true)
                })
                .catch(err => {
                    console.log({authenticated: err});
                    commit("setAuthenticated", false);
                    reject(false)
                })
            })
        },
        logout({commit}) {
            commit("removeToken");
        },
        loginGuest ({commit}) {
            localStorage.setItem('guest', true)
            commit("guest", true);
        },
        getUser ({commit}) {
            return new Promise((resolve, reject) => {
                AuthService.user()
                .then(resp => {
                    commit("setUser", {
                        email: resp.data.email,
                        name: resp.data.name,
                        lastname: resp.data.lastname
                    });
                    resolve(true);
                })
                .catch(err => {
                    reject(err);
                })
            })
        }
    },
};