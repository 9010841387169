import PlotService from '@/services/plot.service.js'

export default {
    namespaced: true,
    state: () => ({
        plots: [],
        search: ''
    }),
    mutations: {
        setPlots (state, value) {
            state.plots = value;
        },
        setSearchPlots (state, value) {
            state.search = value;
        }
    },
    getters: {
        search (state) {
            return state.search;
        },
        plots (state) {
            if (state.search) {
                return state.plots.filter((plot) => {
                    return plot.name.toLowerCase().includes(state.search) || plot.id.toString().includes(state.search)
                });
            }
            return state.plots;
        }
    },
    actions: {
        setSearchPlots ({commit}, search = '') {
            commit("setSearchPlots", search);
        },
        setPlots ({commit}, plots) {
            commit("setPlots", plots);
        },
        getPlots ({commit}) {
            return new Promise((resolve, reject) => {
                PlotService.getPlots()
                .then(resp => {
                    commit("setPlots", resp);
                    resolve(resp);
                })
                .catch(err => {
                    reject(err);
                })
            })
        },
        findPlot ({ state }, plot_id) {
            return new Promise((resolve) => {
                resolve(state.plots.find(plot => plot.id === plot_id));
            })
        },
        findPlotByTreeId ({state}, tree_id) {
            return new Promise ((resolve) => {
                resolve(state.plots.find(plot => plot.lower_range <= tree_id && plot.upper_range >= tree_id));
            })
        }
    },
};