export default {
    namespaced: true,
    state: {
        breadcrumbs: [
            {
                label: 'Dashboard',
                link: '/'
            }
        ]
    },
    getters: {
        breadcrumbs: state      => state.breadcrumbs,
        breadcrumbsCount: state => state.breadcrumbs.length,
    },
    mutations: {
        setBreadcrumbs(state, breadcrumbs) {
            state.breadcrumbs = breadcrumbs
        }
    },
    actions: {
        setBreadcrumbs(context, breadcrumbs) {
            context.commit('setBreadcrumbs', breadcrumbs)
        }
    }
}